import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  // Lista de imágenes para el carrusel
  const images = ["/culture1.png", "/culture2.png", "/culture3.png"];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Efecto para rotar las imágenes automáticamente
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Cambia cada 5 segundos

    return () => clearInterval(interval); // Limpia el intervalo al desmontar
  }, [images.length]);

  return (
    <div>
      {/* Encabezado */}
      <Header />

      {/* Contenido principal */}
      <MainContent images={images} currentImageIndex={currentImageIndex} />
    </div>
  );
}

// Componente del encabezado
function Header() {
  return (
    <header className="navbar">
      <div className="logo">
        <img src="/DomifyLogo.png" alt="Domify Logo" className="logo-image" />
        <span className="logo-text">Domify.me</span>
      </div>
      <nav className="nav-links">
        <a href="#about">About us</a>
        <a href="#services">Services</a>
        <a href="#marketplace">Domify Market Place</a>
        <a href="#WorkWithUs">Work With Us!!</a>
      </nav>
    </header>
  );
}

// Componente principal del contenido
function MainContent({ images, currentImageIndex }) {
  return (
    <main className="main-content">
      <div
        className="background-container"
        style={{
          backgroundImage: `url(${images[currentImageIndex]})`,
        }}
      >
        <div className="text-overlay">
          <h1>Welcome to Domify.me</h1>
          <p>
            Transform your ideas into reality with our platform. Discover
            endless possibilities to grow your business and enhance your
            creativity.
          </p>
          <button className="cta-button">Get Started</button>
        </div>
      </div>
    </main>
  );
}

export default App;